import React from "react";
import BaseLayout from "../../BaseLayout";
import SEO from "../../seo";
import CategoriesData from "../../../client/data/categoriesData.json";
import { $CategoriesDetail } from "./SC_CategoriesDetail";
function CategoriesDetail({ pageContext: { offer, fechedOfferList } }) {
  return (
    <BaseLayout>
      <SEO description={""} title={""} />
      <$CategoriesDetail>
        <span>i am </span>
      </$CategoriesDetail>
    </BaseLayout>
  );
}

export default CategoriesDetail;
